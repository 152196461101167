import React from "react"
import FLayout from "../../../components/flayout";
import SuccessPage from "../../../components/successPage";

class Page extends React.Component {
    render() {
        return (
          <div className="v-100 d-flex flex-column">
          <FLayout showHeaderFooter={false}  darkHeader={true} isFullPage={true} showSingleFooter={false} headerFixed={false} noSelBackground={true} seoFields={seoFields} showAnnouncement={false} loadTimesThankYouPixel={true} loadAdgebraThankYouPixel={true} showB2XTypeSelection={true} isActiveB2XTypeInvestors={true}>
            <SuccessPage isWealth={false} location={this.props.location}/>
          </FLayout>
          </div>
        )
    }
}

const seoFields = {
    description: "Download the MProfit mobile app on your Android or iOS device or login at https://cloud.mprofit.in",
    // lang,
    // meta,
    // keywords,
    title: "Sign-up Success" 
}

export default Page